import { filterEventStoreDefs } from "@fullcalendar/core/structs/event-store";
import moment from "moment";
import { any, instanceOf, object } from "prop-types";
import { db, functions } from "../../services/firebase/firebase.config";
import { TaskConstants } from "../constants/task.constant";
import { TaskModel, TaskStatus } from "../models/task";

export const taskActions = {
  fetchTasksList,
  addNewTask: addNewTask,
  removeSubs,
  clearMsg,
  updateTask,
  showReport,
  curebayReport,
  deleteTask,
};

function removeSubs() {
  var tasks = db.collection("tasks");
  var unsub = tasks.onSnapshot(() => {});
  unsub();
}

function showReport(filters: any) {
  return async (dispatch: (arg0: { type: string; taskList?: any; error?: any }) => void) => {
    try {
      dispatch(request());
      console.log("FFilters Data", filters);
      var getReport = functions.httpsCallable("getReport");
      var result = await getReport(filters);
      var reposonseData = result.data;
      if (result.data.error) {
        throw new Error(result.data.message);
      }
      dispatch(success(reposonseData.data, reposonseData.summaryData, reposonseData.ndrReport));
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }
  };

  function request() {
    return { type: TaskConstants.FETCH_REPORT };
  }

  function success(reports: any, summaryReport: any, ndrReport: any) {
    return { type: TaskConstants.SUCCESS_REPORT, reports, summaryReport, ndrReport };
  }

  function failure(error: any) {
    return { type: TaskConstants.FAILURE_REPORT, error };
  }
}

function fetchTasksList(filter?: any) {
  return async (dispatch: (arg0: { type: string; usersList?: any; error?: any }) => void) => {
    dispatch(request());
    try {
      var tasks = db.collection("tasks");
      var users: any[] = [];
      let total = 0;
      let conditionalTask = tasks.orderBy("createdAt", "desc");
      if (filter) {
        // debugger;
        if (filter.size) {
          conditionalTask = conditionalTask.limit(filter.size);
          console.log("query", filter);
        }
        if (filter?.navigation == "next") {
          let doc = JSON.parse(sessionStorage.getItem("last") || "");
          if (doc) {
            console.log("Doc==", doc);
            conditionalTask = conditionalTask.startAfter(doc);
          }
        } else if (filter?.navigation == "prev") {
          let doc = JSON.parse(sessionStorage.getItem("first") || "");
          if (doc) {
            conditionalTask = conditionalTask.endBefore(doc);
          }
        }
      }
      db.collection("tasks").onSnapshot((snapshot: any) => {
        console.log("ss", snapshot.size);
        total = snapshot.size;
      });
      conditionalTask.onSnapshot(async (taskSnapshot: any) => {
        await db
          .collection("users")
          .where("role", "==", "rider")
          .get()
          .then((results) => {
            results.forEach((doc: any) => {
              users[doc.id] = doc.data();
            });
          })
          .catch((error) => {
            throw new Error(error.message);
          });
        let tasksList: any[] = [];

        taskSnapshot.docs.forEach((item: any) => {
          let data = item.data();
          data.id = item.id;
          data.createdAt = data && data.createdAt ? data.createdAt.toDate() : "";
          data.updatedAt = data && data.updatedAt ? data.updatedAt.toDate() : "";
          data.rider_name =
            data && data.rider
              ? users[data.rider] && users[data.rider].name
                ? `${users[data.rider].name.first_name} ${users[data.rider].name.last_name}`
                : ""
              : "";
          if (data.schedule) {
            if (moment(data.schedule.toDate()).isSameOrBefore(moment.now())) {
              tasksList.push(data);
            } else {
              data.order_status = TaskStatus.scheduled;
              tasksList.push(data);
            }
          } else {
            tasksList.push(data);
          }
        });
        sessionStorage.setItem(
          "last",
          JSON.stringify(taskSnapshot.docs[taskSnapshot.docs.length - 1] || {})
        );
        sessionStorage.setItem("first", JSON.stringify(taskSnapshot.docs[0] || {}));

        console.log("Task Data Found", tasksList, taskSnapshot.docs);
        dispatch(success(tasksList, total));
      });
    } catch (error) {
      console.log("Found Error", error);
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }

    function request() {
      return { type: TaskConstants.FETCH_ALL_TASK };
    }

    function success(tasksList: any, totalTaskCount: any) {
      return { type: TaskConstants.SUCCESS_FETCH_TASK, tasksList, totalTaskCount };
    }

    function failure(error: any) {
      return { type: TaskConstants.FAILURE_FETCH_TASK, error };
    }
  };
}

function addNewTask(taskData: TaskModel) {
  return async (dispatch: (arg0: { type: String; user?: any; error?: any }) => void) => {
    dispatch(request());
    try {
      // console.log("userData", taskData);
      const tasksCollection = db.collection("tasks");
      taskData.task_id = moment().valueOf();
      taskData.createdAt = new Date();
      taskData.updatedAt = new Date();
      let timeline: [{ status: TaskStatus; time: Date }] = [
        { status: TaskStatus.pending, time: new Date() },
      ];
      if (taskData.rider) {
        timeline.push({ status: TaskStatus.assigned, time: new Date() });
        taskData.order_status = TaskStatus.assigned;
      }
      taskData.timeline = timeline;
      const taskDocument = await tasksCollection.add(taskData);
      // functions.useEmulator("localhost", 5001);
      const notification = functions.httpsCallable("sendNotification");
      notification({
        body: {
          taskId: taskDocument.id,
          newOrder: true,
        },
      });

      dispatch(success());
    } catch (error) {
      dispatch(failure(error.message ?? error));
    }
  };

  function request() {
    return { type: TaskConstants.SET_TASK_TO_RIDER };
  }

  function success() {
    return { type: TaskConstants.SUCCESS_TASK_TO_RIDER };
  }

  function failure(error: any) {
    return { type: TaskConstants.FAILURE_TASK_TO_RIDER, error };
  }
}

function updateTask(taskData: TaskModel) {
  return async (dispatch: (arg0: { type: String; user?: any; error?: any }) => void) => {
    dispatch(request());
    // console.log("taskData", taskData);
    const taskCollection = db.collection("tasks");
    try {
      var taskQueryData = await taskCollection.doc(taskData.id?.toString()).get();
      taskData.updatedAt = new Date();
      if (taskQueryData && taskQueryData.exists) {
        if (taskData.order_status !== (taskQueryData.data() as TaskModel).order_status) {
          taskData.timeline?.push({ status: taskData.order_status, time: new Date() });
        }
        await taskCollection.doc(taskData.id?.toString()).update(taskData);
        const notification = functions.httpsCallable("sendNotification");
        notification({
          body: {
            taskId: taskQueryData.id,
          },
        });
        dispatch(success("task updated Successfully"));
      } else {
        throw new Error("task Not Found");
      }
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
      console.error(error);
    }
  };

  function request() {
    return { type: TaskConstants.REQUEST_UPDATE_TASK };
  }

  function success(newUser: any) {
    return { type: TaskConstants.SUCCESS_UPDATE_TASK, newUser };
  }

  function failure(error: any) {
    return { type: TaskConstants.FAILURE_UPDATE_TASK, error };
  }
}

function clearMsg() {
  return (dispatch: any) => {
    dispatch({ type: TaskConstants.CLEAR_MSG });
  };
}

function curebayReport(filters?: any) {
  return async (dispatch: (arg0: { type: String; curebayReport?: any; error?: any }) => void) => {
    dispatch(request());
    try {
      const orderColl = db.collection("tasks");
      let orderCollWhere = orderColl.orderBy("createdAt", "desc");
      if (filters) {
        if (filters.fromDate) {
          orderCollWhere = orderCollWhere.where("createdAt", ">=", new Date(filters.fromDate));
        }
        if (filters.toDate) {
          orderCollWhere = orderCollWhere.where("updatedAt", "<=", new Date(filters.toDate));
        }
        if (filters.rider) {
          orderCollWhere = orderCollWhere.where("rider", "==", filters.rider);
        }
        if (filters.merchantName) {
          orderCollWhere = orderCollWhere.where("sender_name", "==", filters.merchantName);
        }
        if (filters.patientName) {
          orderCollWhere = orderCollWhere.where("receiver_name", "==", filters.patientName);
        }
      }
      const orderDocs = (await orderCollWhere.get()).docs;
      let ordersData = orderDocs.map((doc) => doc.data());

      const rejectedOrdersDocs = (await db.collection("rejected_orders").get()).docs;
      let rejectedOrdersData = rejectedOrdersDocs.map((doc) => doc.data());
      let createdOrders: number = 0;
      let deliveredOrders: number = 0;
      let assignedOrders: number = 0;
      let acceptedOrders: number = 0;
      let rejectedOrders: number = 0;
      let pendingOrders: number = 0;
      let pickedUpOrders: number = 0;
      let rejectedOrdersByField: any = {};
      let deliveredOrdersByField: any = {};
      let createdOrdersByField: any = {};
      let rejectedReasonCount: any = {};

      let ordersByCategory: any = {};
      ordersData.map((order: any) => {
        order.timeline?.map((timeline: any) => {
          // #region Get created Orders
          if (timeline.status === TaskStatus.pending) {
            // if (filters) {
            // if (filters.fromDate) {
            //   if (moment(timeline.time.toDate()).isSameOrAfter(filters.fromDate, "date")) {
            //     if (filters.toDate) {
            //       if (moment(timeline.time.toDate()).isSameOrBefore(filters.toDate)) {
            //         createdOrders += 1;
            //       }
            //     } else {
            //       createdOrders += 1;
            //     }
            //   }
            // } else {
            // createdOrders += 1;
            // }
            createdOrders += 1;
            let key = moment(timeline.time.toDate()).format("DD-MMM-yyyy");
            if (createdOrdersByField[key]) createdOrdersByField[key] += 1;
            else createdOrdersByField[key] = 1;
            // }
          }
          //#endregion

          // #region Get delivered Orders
          if (timeline.status === TaskStatus.delivered) {
            // if (filters) {
            // if (filters.fromDate) {
            //   if (moment(timeline.time.toDate()).isSameOrAfter(filters.fromDate, "date")) {
            //     if (filters.toDate) {
            //       if (moment(timeline.time.toDate()).isSameOrBefore(filters.toDate)) {

            //         // deliveredOrders += 1;
            //       }
            //     } else {
            //       // deliveredOrders += 1;
            //     }
            //   }
            // } else {
            //   // deliveredOrders += 1;
            // }
            deliveredOrders += 1;
            let key = moment(timeline.time.toDate()).format("DD-MMM-yyyy");
            if (deliveredOrdersByField[key]) deliveredOrdersByField[key] += 1;
            else deliveredOrdersByField[key] = 1;
            // debugger;

            // }
          }
          //#endregion

          //#region Get Assigned Orders
          if (timeline.status === TaskStatus.assigned) {
            // if (filters) {
            //   if (filters.fromDate) {
            //     if (moment(timeline.time.toDate()).isSameOrAfter(filters.fromDate, "date")) {
            //       if (filters.toDate) {
            //         if (moment(timeline.time.toDate()).isSameOrBefore(filters.toDate)) {
            //           assignedOrders += 1;
            //         }
            //       } else {
            //         assignedOrders += 1;
            //       }
            //     }
            //   } else {
            assignedOrders += 1;
            // }
            assignedOrders += 1;
            // }
          }
          //#endregion

          //#region Get Accepted Orders
          if (timeline.status === TaskStatus.accepted) {
            // if (filters) {
            // if (filters.fromDate) {
            //   if (moment(timeline.time.toDate()).isSameOrAfter(filters.fromDate, "date")) {
            //     if (filters.toDate) {
            //       if (moment(timeline.time.toDate()).isSameOrBefore(filters.toDate)) {
            //         acceptedOrders += 1;
            //       }
            //     } else {
            //       acceptedOrders += 1;
            //     }
            //   }
            // } else {
            //   acceptedOrders += 1;
            // }
            acceptedOrders += 1;
            // }
          }
          //#endregion

          // #region Get PickedUp Orders
          if (
            timeline.status === TaskStatus.picked_up ||
            timeline.status === TaskStatus.in_transit
          ) {
            // if (filters) {
            // if (filters.fromDate) {
            //   if (moment(timeline.time.toDate()).isSameOrAfter(filters.fromDate, "date")) {
            //     if (filters.toDate) {
            //       if (moment(timeline.time.toDate()).isSameOrBefore(filters.toDate)) {
            //         createdOrders += 1;
            //       }
            //     } else {
            //       createdOrders += 1;
            //     }
            //   }
            // } else {
            // createdOrders += 1;
            // }
            pickedUpOrders += 1;
            // }
          }
          //#endregion

          //#region Get Rejected Orders
          if (timeline.status === TaskStatus.rejected) {
            // if (filters) {
            //   if (filters.fromDate) {
            //     if (moment(timeline.time.toDate()).isSameOrAfter(filters.fromDate, "date")) {
            //       if (filters.toDate) {
            //         if (moment(timeline.time.toDate()).isSameOrBefore(filters.toDate)) {
            //           rejectedOrders += 1;
            //         }
            //       } else {
            //         rejectedOrders += 1;
            //       }
            //     }
            //   } else {
            //     rejectedOrders += 1;
            //   }
            rejectedOrders += 1;
            let key = moment(timeline.time.toDate()).format("DD-MMM-yyyy");
            if (rejectedOrdersByField[key]) rejectedOrdersByField[key] += 1;
            else rejectedOrdersByField[key] = 1;
            // debugger;

            // }
          }
          //#endregion

          if (filters.forDate) {
            if (
              moment(filters.forDate).isSame(timeline.time, "date") &&
              timeline.status === TaskStatus.pending
            ) {
            }
          }
        });

        //#region Total Current Pending Orders
        if (order.order_status === TaskStatus.pending) {
          pendingOrders += 1;
        }
        //#endregion

        //#region
        if (ordersByCategory[order.task_type]) ordersByCategory[order.task_type] += 1;
        else ordersByCategory[order.task_type] = 1;
        //#endregion
        return null;
      });

      rejectedOrdersData.map((rejectedData: any) => {
        const reason = rejectedData.rejection_reason;
        if (!rejectedReasonCount[reason]) rejectedReasonCount[reason] = 1;
        else rejectedReasonCount[reason] += 1;
        return null;
      });

      dispatch(
        success({
          createdOrders,
          deliveredOrders,
          assignedOrders,
          acceptedOrders,
          rejectedOrders,
          pendingOrders,
          pickedUpOrders,
          rejectedReasonCount,
          deliveredOrdersByField,
          rejectedOrdersByField,
          createdOrdersByField,
          ordersByCategory,
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        //
        dispatch(failure(error.message));
      } else {
        // console.error(error);
        //
        dispatch(failure(error));
      }
    }
  };

  function request() {
    return { type: TaskConstants.FETCH_CUREBAY_REPORT };
  }

  function success(report: object) {
    return { type: TaskConstants.SUCCESS_CUREBAY_REPORT, curebayReport: report };
  }

  function failure(error: any) {
    return { type: TaskConstants.FAILURE_CUREBAY_REPORT, error };
  }
}

function deleteTask(taskData: TaskModel) {
  return async (dispatch: (arg0: { type: String; user?: any; error?: any }) => void) => {
    dispatch(request());
    // console.log("taskData", taskData);
    const taskCollection = db.collection("tasks");
    try {
      taskCollection
        .doc(taskData.id?.toString())
        .delete()
        .then(() => {
          dispatch(success());
        })
        .catch((err) => {
          throw new Error(`${err?.message}`);
        });

      dispatch(success());
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
      console.error(error);
    }
  };

  function request() {
    return { type: TaskConstants.REQUEST_DELETE_TASK };
  }

  function success() {
    return { type: TaskConstants.SUCCESS_DELETE_TASK };
  }

  function failure(error: any) {
    return { type: TaskConstants.FAILURE_DELETE_TASK, error };
  }
}
