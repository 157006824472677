import { combineReducers } from "redux";
import themeReducer from "./themeReducers";
import authReducer from "./authReducers";
import usersReducer from "./usersReducers";
import tasksReducer from "./tasksReducers";
import rejectReducer from "./rejectReducer";
import deliveryTypeReducer from "./deliveryTypeReducers";
import configReducers from "./configReducer";

export const rootReducer = combineReducers({
  authReducer: authReducer,
  themeReducer: themeReducer,
  usersReducer: usersReducer,
  tasksReducer: tasksReducer,
  deliveryTypeReducer: deliveryTypeReducer,
  configReducer: configReducers,
  rejectReducer: rejectReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
