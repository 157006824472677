import { LoginConstant } from "../constants/auth.constant";

const initialState = {
  //@ts-ignore
  user: localStorage && localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
  isUserLoggedIn: false,
  msg: "",
  isLoading: false,
};

export type AuthInitialStateType = {
  user: object;
  isUserLoggedIn: boolean;
  msg: string;
  isLoading: boolean;
};
export default function reducer(
  state = initialState,
  action: { type: any; user: any; error: any }
): AuthInitialStateType {
  console.log("action", action);
  switch (action.type) {
    case LoginConstant.REQUEST_LOGIN:
      let userObject = action && action.user ? action.user : {};
      if (userObject.rememberMe) {
        localStorage.setItem(btoa("user"), btoa(JSON.stringify(userObject)));
      }
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case LoginConstant.SUCCESS_LOGIN:
      console.log("actions", action);
      let user = action && action.user ? action.user : {};
      return {
        ...state,
        user: user,
        isUserLoggedIn: true,
        isLoading: false,
      };
    case LoginConstant.FAILURE_LOGIN:
      let message = action && action.error ? action.error : {};
      return {
        ...state,
        msg: message,
        isLoading: false,
      };
    case LoginConstant.LOG_OUT:
      return {
        ...state,
        user: {},
        isLoading: false,
        isUserLoggedIn: false,
        msg: "",
      };
    case LoginConstant.CLEARM_MSG:
      return {
        ...state,
        msg: "",
      };
    default:
      return state;
  }
}
