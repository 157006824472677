import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { GlobalStyleProps } from './../types/types'
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const CreateTask = styled.div`
  width:100%,
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Auth: React.FC = ({ children }) => {
  // @ts-ignore
  var url = children && children.props && children.props.location && children.props.location.pathname ? children.props.location.pathname : '';
  console.log("children :::>", url)
  if (url !== '/task/create') {
    return (
      <Root  >
        <CssBaseline />
        <GlobalStyle />
        {children}
      </Root>
    );
  }
  else {
    return (
      <CreateTask>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </CreateTask>
    );
  }

}

export default Auth;
