export const TaskConstants = {
  FETCH_ALL_TASK: "FETCH_ALL_TASK",
  FAILURE_FETCH_TASK: "FAILURE_FETCH_TASK",
  SUCCESS_FETCH_TASK: "SUCCESS_FETCH_TASK",
  SET_TASK_TO_RIDER: "SET_TASK_TO_RIDER",
  FAILURE_TASK_TO_RIDER: "FAILURE_TASK_TO_RIDER",
  SUCCESS_TASK_TO_RIDER: "SUCCESS_TASK_TO_RIDER",
  REQUEST_UPDATE_TASK: "REQUEST_UPDATE_TASK",
  FAILURE_UPDATE_TASK: "FAILURE_UPDATE_TASK",
  SUCCESS_UPDATE_TASK: "SUCCESS_UPDATE_TASK",
  FETCH_REPORT: "FETCH_REPORT",
  SUCCESS_REPORT: "SUCCESS_REPORT",
  FAILURE_REPORT: "FAILURE_REPORT",
  FETCH_CUREBAY_REPORT: "FETCH_CUREBAY_REPORT",
  SUCCESS_CUREBAY_REPORT: "SUCCESS_CUREBAY_REPORT",
  FAILURE_CUREBAY_REPORT: "FAILURE_CUREBAY_REPORT",
  CLEAR_MSG: "CLEARM_MSG",
  REQUEST_DELETE_TASK: "REQUEST_DELETE_TASK",
  FAILURE_DELETE_TASK: "FAILURE_DELETE_TASK",
  SUCCESS_DELETE_TASK: "SUCCESS_DELETE_TASK",
};
