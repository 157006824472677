import React from "react";
import async from "../components/Async";
import { Package, Users, List } from "react-feather";
import { Toc } from "@material-ui/icons";
import CurebayReport from "../pages/reports/graphReport";

const Supervisor = async(() => import("../pages/users/supervisor"));
const Admin = async(() => import("../pages/users/admin"));
const Rider = async(() => import("../pages/users/rider"));

const EditUser = async(() => import("../pages/users/editUser"));
const AddUser = async(() => import("../pages/users/addUser"));
const TasksList = async(() => import("../pages/tasks/listTasks"));
const AddTask = async(() => import("../pages/tasks/newTasks"));
const CreateTask = async(() => import("../pages/tasks/createTasks"));
const EditTask = async(() => import("../pages/tasks/editTasks"));
const AddClient = async(() => import("../pages/client/addClient"));
const viewClient = async(() => import("../pages/client/viewclient"));
const RejectReasonList = async(() => import("../pages/reject_reasons/listRejectReason"));
const AddRejectReason = async(() => import("../pages/reject_reasons/addRejectReason"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ForgotPassword = async(() => import("../pages/auth/ForgotPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
// const Main = async(() => import("../pages/auth/Main"));
// Dashboards components
// const Dashboard = async(() => import("../pages/dashboards/Default/index"));
const ReportView = async(() => import("../pages/reports/viewReport"));
// const Report = async(() => import("../pages/reports/Report"));

const Dashboards = {
  id: "Dashboard",
  path: "/Dashboard",
  icon: <Package />,
  containsHome: true,
  children: null,
  component: CurebayReport,
};

const userEdit = {
  id: "EditUser",
  path: "/user/edit",
  // icon: <Package />,
  // containsHome: true,
  children: null,
  component: EditUser,
};

const TaskEdit = {
  id: "EditTask",
  path: "/task/edit",
  // icon: <Package />,
  // containsHome: true,
  children: null,
  component: EditTask,
};

const users = {
  id: "User Management",
  path: "/user",
  icon: <Users />,
  containsHome: false,
  children: [
    {
      path: "/user/admin",
      name: "Admin",
      component: Admin,
    },
    {
      path: "/user/suerpvisor",
      name: "Supervisor",
      component: Supervisor,
    },
    {
      path: "/user/rider",
      name: "Rider",
      component: Rider,
    },
    {
      path: "/user/add",
      name: "Add User",
      component: AddUser,
    },
  ],
  component: null,
};

const Reports = {
  id: "User",
  path: "/user",
  icon: <Users />,
  containsHome: false,
};

const tasks = {
  id: "Task Management",
  path: "/task",
  icon: <Toc />,
  containsHome: false,
  children: [
    {
      path: "/tasks/show",
      name: "Show Tasks",
      component: TasksList,
    },
    {
      path: "/tasks/add",
      name: "Add New Task",
      component: AddTask,
    },
    {
      path: "/tasks/reports",
      name: "Delivery Report",
      component: ReportView,
    },
  ],
  component: null,
};
const rejects = {
  id: "Rejection Management",
  path: "/rejects",
  icon: <Toc />,
  containsHome: false,
  children: null,
  component: RejectReasonList,
};
const client = {
  id: "Client",
  path: "/client",
  icon: <List />,
  containsHome: false,
  children: [
    {
      path: "/client/add",
      name: "Add New Client",
      component: AddClient,
    },
    {
      path: "/clients/view",
      name: "Show Clients",
      component: viewClient,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/",
  icon: <Users />,
  children: [
    // {
    //   path: "/",
    //   name: "Home",
    //   component: Main
    // },
    // {
    //   path: "/uploadDocs/:id",
    //   name: "Home",
    //   component: uploadDocuments
    // },
    {
      path: "/",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/task/create",
      name: "Create Task",
      component: CreateTask,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },

    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [Dashboards, tasks, users, userEdit, TaskEdit, rejects];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [Dashboards, tasks, users, rejects];
