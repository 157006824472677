export const ConfigConstant = {
  UPDATE_AUTO_ASSIGN: "REQUEST_UPDATE_AUTO_ASSIGN",
  SUCCESS_AUTO_ASSIGN_UPDATE: "SUCCESS_AUTO_ASSIGN_UPDATE",
  FAILURE_AUTO_ASSIGN_UPDATE: "FAILURE_AUTO_ASSIGN_UPDATE",
  FETCH_CONFIG: "FETCH_CONFIG",
  SUCCESS_CONFIG: "SUCCESS_CONFIG_FETCH",
  FAILURE_CONFIG: "FAILUER_CONFIG_FETCH",
  // LOG_OUT: "LOG_OUT",
  CLEAR_MSG: "CLEAR_MSG",
};
