export const ReasonConstants = {
  FETCH_ALL_REASON: "FETCH_ALL_REASON",
  FAILURE_FETCH_REASON: "FAILURE_FETCH_REASON",
  SUCCESS_FETCH_REASON: "SUCCESS_FETCH_REASON",

  ADD_REASON: "ADD_REASON",
  FAILURE_ADD_REASON: "FAILURE_ADD_REASON",
  SUCCESS_ADD_REASON: "SUCCESS_ADD_REASON",

  DELETE_REASON: "DELETE_REASON",
  FAILURE_DELETE_REASON: "FAILURE_DELETE_REASON",
  SUCCESS_DELETE_REASON: "SUCCESS_DELETE_REASON",

  CLEAR_MSG: "CLEAR_MSG",
};
