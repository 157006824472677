import { DeliveryConstants } from "../constants/delivery.constant";

const initialState = {
  deliveryTypeList: [],
  msg: "",
  isLoading: false,
  error: false,
};

export type InitialStateType = {
  deliveryTypeList: object;
  msg: string;
  isLoading: boolean;
  error: boolean;
};

export default function reducer(
  state = initialState,
  action: {
    type: any;
    deliveryList: any;
    newTask: any;
    error: any;
  }
): InitialStateType {
  switch (action.type) {
    case DeliveryConstants.FETCH_ALL_DELIVERY:
      return {
        ...state,
        deliveryTypeList: [],
        isLoading: true,
        error: false,
      };
    case DeliveryConstants.SUCCESS_FETCH_DELIVERY:
      return {
        ...state,
        deliveryTypeList: action.deliveryList ?? [],
        msg: "success",
        isLoading: false,
        error: false,
      };
    case DeliveryConstants.FAILURE_FETCH_DELIVERY:
      return {
        ...state,
        msg: action && action.error ? action.error : "",
        isLoading: false,
        error: true,
      };
    case DeliveryConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
        isLoading: false,
      };
    default:
      return state;
  }
}
