import React from "react";
import {HashRouter as Router, Route, RouteComponentProps, Switch} from "react-router-dom";
import {authLayoutRoutes, dashboardLayoutRoutes} from "./index";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import {RouteInfoType} from "../types/types";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  [...routes].map(({component: Component, children, path, id}, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        <Route
          key={index}
          path={element.path}
          exact
          render={(props: RouteComponentProps) => (
            <Layout>
              <element.component {...props} />
            </Layout>
          )}
        />
      ))
    ) : Component ?
      (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
      : null
  });
const Routes = () => {
  return (
    <Router>
      <Switch>
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404/>
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  )
};

export default Routes;
