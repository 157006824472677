import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import styled from "styled-components/macro";

import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Loader from "../../components/Loader";
import { connect, useDispatch, useSelector } from "react-redux";
import { taskActions } from "../../redux/actions/taskAction";
import { userActions } from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { compose } from "recompose";
import { Bar, Pie } from "react-chartjs-2";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { Chip as MuiChip } from "@material-ui/core";
import { TaskModel, TaskStatus } from "../../redux/models/task";
import { blue, green, orange, red } from "@material-ui/core/colors";

const Chip = styled(MuiChip) <{ rgbcolor?: string }>`
  height: 25px;
  font-size: 100%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
  width: 100px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const acceptedIcon = require("../../assets/icons/orders/accepted.png");
const assignedIcon = require("../../assets/icons/orders/assigned.png");
const createdIcon = require("../../assets/icons/orders/created.png");
const deliveredIcon = require("../../assets/icons/orders/delivered.png");
const rejectedIcon = require("../../assets/icons/orders/rejected.png");
const pickedUpIcon = require("../../assets/icons/orders/pickedUp.png");

const defaultProps = {
  bgcolor: "#FBF5E9",
  borderColor: "text.primary",
  m: 1,

  style: { width: "2.5rem", height: "2.5rem" },
};

function CurebayReport() {
  const classes = useStyles();

  const dispatch = useDispatch();
  // const [tabIndex, setTabIndex] = useState(0);
  const [fromDate, setFromDate] = useState(0);
  const [forDate, setForDate] = useState(0);
  const [toDate, setToDate] = useState(0);
  const [rider, setRider] = useState("");
  // const [merchantName, setMerchantName] = useState("");
  // const [consumerName, setConsumerName] = useState("");


  const isReportLoading = useSelector((state) => {
    //@ts-ignore
    if (state && state.tasksReducer && state.tasksReducer.isLoading) {
      //@ts-ignore
      return state.tasksReducer.isLoading;
    }
    return false;
  });

  const getTasksList = () => {
    dispatch(taskActions.fetchTasksList({ size: 10 }));
    return () => {
      taskActions.removeSubs();
    };
  };

  useEffect(getTasksList, []);

  const tasksList: Array<TaskModel> = useSelector((state) => {
    //@ts-ignore
    if (state && state.tasksReducer && state.tasksReducer.tasksList) {
      //@ts-ignore
      return state.tasksReducer.tasksList;
    }
    return [];
  });

  const isTableLoading = useSelector((state) => {
    //@ts-ignore
    if (state && state.tasksReducer && state.tasksReducer.isLoading) {
      //@ts-ignore
      return state.tasksReducer.isLoading;
    }
    return false;
  });

  const checkColor = (status: string) => {
    // @ts-ignore
    if (status === "delivered") {
      return green[500];
    }
    // @ts-ignore
    if (
      status === "accepted" ||
      status === "pending" ||
      status === "picked_up" ||
      status === "in_transit" ||
      status === "assigned" ||
      status === "scheduled"
    ) {
      return orange[500];
    }
    // @ts-ignore
    if (status === "rejected" || status === "delivery_failed") {
      return red[500];
    }
    if (status === TaskStatus.stale) {
      return blue[400];
    }
  };

  const tableOptions: any = {
    rowsPerPage: 10,
    // rowsPerPageOptions: [10, 50, 100],
    tableBodyHeight: "550px",
    jumpToPage: false,
    responsive: "stacked",
    filter: false,
    download: false,
    print: false,
    selectableRows: "none",
    displayRowCheckbox: false,
    selectableRowsOnClick: false,
    textLabels: {
      body: {
        noMatch: isTableLoading ? <CircularProgress /> : "No data found",
      },
    },
  };

  const getFilteredReports = () => {
    dispatch(
      taskActions.curebayReport({
        fromDate: fromDate || undefined,
        forDate: forDate || undefined,
        toDate: toDate || undefined,
        rider: rider || undefined,
      })
    );
  };

  useEffect(getFilteredReports, []);

  const curebayReports: any = useSelector((state) => {
    //@ts-ignore
    if (state && state.tasksReducer && state.tasksReducer.curebayReport) {
      //@ts-ignore
      return state.tasksReducer.curebayReport;
    }
    return null;
  });

  //@ts-ignore
  const riderList = useSelector<Array<UserModel>>((state) => state.usersReducer.usersList);

  const getUsersList = () => {
    dispatch(userActions.fetchUserList("rider"));
  };

  useEffect(getUsersList, []);

  const riderListChildren: any = [
    {
      title: "All Riders",
      id: "",
    },
  ];

  // @ts-ignore
  riderList.map((data: any) => {
    let fname = data && data.name && data.name.first_name ? data.name.first_name : "";
    let lname = data && data.name && data.name.last_name ? data.name.last_name : "";
    // @ts-ignore
    return riderListChildren.push({
      title: fname + (lname ? " " : "") + lname,
      status: data.on_duty,
      id: data.id,
    });
  });

  if (isReportLoading) {
    return <Loader />;
  }

  let barChartData: any = {};

  let barChartKey: any[] = [];
  let pieKeys: any = [];

  let deliveredOrdersValue: any = [];
  let rejectedOrdersValue: any = [];
  let createdOrdersValue: any = [];

  let rejectedReasonData: any = [];

  if (curebayReports) {
    const rejectedOrders = curebayReports.rejectedOrdersByField || {};
    const deliveredOrders = curebayReports.deliveredOrdersByField || {};
    const rejectedData = curebayReports.rejectedReasonCount || {};
    const createdOrders = curebayReports.createdOrdersByField || {};

    if (curebayReports.deliveredOrdersByField) {
      Object.keys(deliveredOrders).map((key) => {
        if (!barChartData[key]) {
          barChartData[key] = {};
          barChartData[key]["delivered"] = deliveredOrders[key];
          barChartData[key]["rejected"] = 0;
          barChartData[key]["created"] = 0;
        } else {
          barChartData[key]["delivered"] += deliveredOrders[key];
        }
        if (barChartKey.indexOf(key) === -1) barChartKey.push(key);
        return null;
      });
    }
    if (curebayReports.rejectedOrdersByField) {
      Object.keys(rejectedOrders).map((key) => {
        if (!barChartData[key]) {
          barChartData[key] = {};
          barChartData[key]["rejected"] = rejectedOrders[key];
          barChartData[key]["delivered"] = 0;
          barChartData[key]["created"] = 0;
        } else {
          barChartData[key]["rejected"] += rejectedOrders[key];
        }
        if (barChartKey.indexOf(key) === -1) barChartKey.push(key);
        return null;
      });
    }
    if (curebayReports.createdOrdersByField) {
      Object.keys(createdOrders).map((key) => {
        if (!barChartData[key]) {
          barChartData[key] = {};
          barChartData[key]["created"] = createdOrders[key];
          barChartData[key]["delivered"] = 0;
          barChartData[key]["rejected"] = 0;
        } else {
          barChartData[key]["created"] += createdOrders[key];
        }
        if (barChartKey.indexOf(key) === -1) barChartKey.push(key);
        return null;
      });
    }

    if (rejectedData) {
      Object.keys(rejectedData).map((key) => {
        pieKeys.push(key);
        rejectedReasonData.push(rejectedData[key]);

        return null;
      });
    }

    barChartKey = barChartKey.sort((a: any, b: any) => {
      return moment(a).isBefore(moment(b)) ? -1 : 1;
    });
    console.log("sorted Date", barChartKey);
    barChartKey.map((key) => {
      deliveredOrdersValue.push(barChartData[key].delivered);
      rejectedOrdersValue.push(barChartData[key].rejected);
      createdOrdersValue.push(barChartData[key].created);
      return null;
    });
  }

  const barData = {
    labels: barChartKey,
    datasets: [
      {
        label: "Created",
        data: createdOrdersValue,
        backgroundColor: "rgb(54, 162, 235)",
        maxBarThickness: 30,
      },
      {
        label: "Delivered",
        data: deliveredOrdersValue,
        backgroundColor: "rgb(75, 192, 192)",
        maxBarThickness: 30,
      },
      {
        label: "Rejected",
        data: rejectedOrdersValue,
        backgroundColor: "rgb(255, 99, 132)",
        maxBarThickness: 30,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      position: "right",
    },
    width: "100%",

  };

  const pieData = {
    labels: pieKeys,
    datasets: [
      {
        label: "# of Reasons",
        data: rejectedReasonData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 19, 119, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 19, 119, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getStatusString = (status: string) => {
    switch (status) {
      case "delivery_failed":
        return "Failed";
      default:
        return capitalizeFirstLetter(String(status).replace("_", " "));
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} lg={4} xl={2}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <Box
                  borderRadius="50%"
                  {...defaultProps}
                  width="30px"
                  height="30px"
                  style={{ display: "flex", marginLeft: "10px" }}
                >
                  <img src={createdIcon} alt="created" />
                </Box>
              </div>
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: "black", marginBottom: "0px", marginTop: "5px" }}
                >
                  {curebayReports.createdOrders || 0}
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Created</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} lg={4} xl={2}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <Box
                  borderRadius="50%"
                  {...defaultProps}
                  width="30px"
                  height="30px"
                  style={{ display: "flex", marginLeft: "10px" }}
                >
                  <img src={deliveredIcon} alt="Order" />
                </Box>
              </div>
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: "black", marginBottom: "0px", marginTop: "5px" }}
                >
                  {curebayReports.deliveredOrders || 0}
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Delivered</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} lg={4} xl={2}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <Box
                  borderRadius="50%"
                  {...defaultProps}
                  width="30px"
                  height="30px"
                  style={{ display: "flex", marginLeft: "10px" }}
                >
                  <img src={assignedIcon} alt="Order" />
                </Box>
              </div>
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: "black", marginBottom: "0px", marginTop: "5px" }}
                >
                  {curebayReports.assignedOrders || 0}
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Assigned</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} lg={4} xl={2}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <Box
                  borderRadius="50%"
                  {...defaultProps}
                  width="30px"
                  height="30px"
                  style={{ display: "flex", marginLeft: "10px" }}
                >
                  <img src={acceptedIcon} alt="Order" />
                </Box>
              </div>
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: "black", marginBottom: "0px", marginTop: "5px" }}
                >
                  {curebayReports.acceptedOrders || 0}
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Accepted</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} lg={4} xl={2}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <Box
                  borderRadius="50%"
                  {...defaultProps}
                  width="30px"
                  height="30px"
                  style={{ display: "flex", marginLeft: "10px" }}
                >
                  <img src={pickedUpIcon} alt="Order" />
                </Box>
              </div>
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: "black", marginBottom: "0px", marginTop: "5px" }}
                >
                  {curebayReports.pickedUpOrders || 0}
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders PickedUp</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} lg={4} xl={2}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <Box
                  borderRadius="50%"
                  {...defaultProps}
                  width="30px"
                  height="30px"
                  style={{ display: "flex", marginLeft: "10px" }}
                >
                  <img src={rejectedIcon} alt="Order" />
                </Box>
              </div>
              <div>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: "black", marginBottom: "0px", marginTop: "5px" }}
                >
                  {curebayReports.rejectedOrders || 0}
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Rejected</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
      </Grid>

      {/* 2nd card */}
      <div>
        {/* <Box> */}
        <Grid container style={{ marginTop: "15px", height: "100%", width: "100%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box mt={10} style={{ marginBottom: "40px" }}>
              <MUIDataTable
                title="Latest 10 Orders"
                options={tableOptions}
                data={tasksList.map((task: TaskModel) => {
                  var pickupDate = "";
                  var dropDate = "";
                  if (task && task.timeline && task.timeline.length > 0) {
                    task.timeline.map((data: any) => {
                      if (data.status === TaskStatus.picked_up) {
                        pickupDate = moment(data.time.toDate()).format("DD-MMM-YY h:mm A");
                      }
                      if (data.status === TaskStatus.delivered) {
                        dropDate = moment(data.time.toDate()).format("DD-MMM-YY h:mm A");
                      }
                      return "";
                    });
                  }

                  console.log("Data==", task.updatedAt, task.createdAt);
                  return [
                    task && task.order_id ? task.order_id : "NA",
                    task && task.task_id ? task.task_id : "NA",
                    task && task.task_type ? task.task_type.replaceAll("_", " ") : "NA",
                    task && task.sender_name ? task.sender_name : "NA",
                    task && task.receiver_name ? task.receiver_name : "NA",
                    task && task.payment_information && task.payment_information.amount
                      ? task.payment_information.amount
                      : "NA",
                    task && task.rider_name ? task.rider_name : "NA",
                    // task && task.rejection_reason ? task.rejection_reason : "NA",
                    // task && task.createdAt ? moment(task.createdAt).format("DD-MMM-YY h:mm A") : "NA",
                    task && task.updatedAt
                      ? moment(task.updatedAt).format("DD-MMM-YY h:mm A")
                      : "NA",

                    task && task.order_status ? task.order_status : "NA",
                    // task && task.items ? task.items : [],
                    task && task.sender_phone ? task.sender_phone : "NA",
                    task && task.receiver_phone ? task.receiver_phone : "NA",
                    pickupDate,
                    dropDate,
                  ];
                })}
                columns={[
                  {
                    name: "Order ID",
                    label: "Order ID",
                    options: {
                      filter: false,
                      display: true,
                    },
                  },
                  {
                    name: "ID",
                    label: "ID",
                    options: {
                      filter: false,
                      display: false,
                    },
                  },
                  "Order Type",
                  "Sender Name",
                  "Receiver Name",
                  {
                    name: "Amount",
                    label: "Amount",
                    options: {
                      filter: false,
                      display: false,
                    },
                  },
                  "Assigned To",
                  // "Cancel Reason",
                  {
                    name: "Updated Date",
                    label: "Updated Date",
                    options: {
                      sort: true,
                    },
                  },
                  {
                    name: "Status",
                    label: "Status",
                    options: {
                      sort: true,
                      customBodyRender: (data: any) => {
                        return data ? (
                          <Chip
                            rgbcolor={checkColor(data)}
                            //@ts-ignore
                            label={getStatusString(data)}
                          />
                        ) : (
                          ""
                        );
                      },
                    },
                  },
                  {
                    name: "Sender Phone",
                    label: "Sender Phone",
                    options: {
                      display: false,
                    },
                  },
                  {
                    name: "Receiver Phone",
                    label: "Receiver Phone",
                    options: {
                      display: false,
                    },
                  },
                  {
                    name: "Pickup Date",
                    label: "Pickup Date",
                    options: {
                      display: false,
                      viewColumns: false,
                    },
                  },
                  {
                    name: "Drop Date",
                    label: "Drop Date",
                    options: {
                      display: false,
                      viewColumns: false,
                    },
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box>
                  <Card variant="outlined">
                    <CardContent>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "bolder",
                            marginBottom: "10px",
                            marginTop: "1px",
                            paddingLeft: "8px",
                          }}
                        >
                          Created V/S Delivered V/S Rejected Orders
                        </p>
                      </div>
                      <Box>
                        <Bar data={barData} options={options} />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Box>
                  <Card variant="outlined" style={{ marginTop: "15px" }}>
                    <CardContent>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "bolder",
                          marginBottom: "0px",
                          marginTop: "1px",
                          paddingLeft: "8px",
                        }}
                      >
                        Orders By type
                      </p>
                      <Box mr={4} mt={5}>
                        <TableContainer component={Paper} style={{ maxHeight: "410px" }}>
                          <Table aria-label="simple table">
                            
                            <TableBody>
                              {curebayReports &&
                                curebayReports.ordersByCategory &&
                                Object.keys(curebayReports.ordersByCategory)
                                  .filter((str) => str && str !== "undefined" && str !== "null")
                                  .map((key) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="left">
                                          {key.replaceAll("_", " ")}
                                        </TableCell>

                                        <TableCell align="right">
                                          {curebayReports.ordersByCategory[key]}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Box style={{ display: "grid" }}>
                  <Card variant="outlined" style={{ marginTop: "15px" }}>
                    <CardContent>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "bolder",
                          marginBottom: "0px",
                          marginTop: "1px",
                          paddingLeft: "8px",
                        }}
                      >
                        Rejected Orders Reasons
                      </p>
                      <Box mr={4} mt={5}>
                        <Pie
                          data={pieData}
                          options={{
                            legend: {
                              position: "right",
                            },
                          }}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        {/* </Box> */}
      </div>
    </div>
  );
}

//@ts-ignore
function mapStateToProps(state) {
  return {
    message: state.usersReducer.msg,
  };
}

//@ts-ignore
function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(connect(mapStateToProps, matchDispatchToProps))(CurebayReport);
