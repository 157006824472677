import { ReasonConstants } from "../constants/rejects.constat";

const initialState = {
  reasonList: [],
  msg: "",
  isLoading: false,
};

export type AuthInitialStateType = {
  reasonList: object;
  msg: string;
  isLoading: boolean;
};
export default function reducer(
  state = initialState,
  action: {
    type: any;
    reasonList: any;
    error: any;
  }
): AuthInitialStateType {
  switch (action.type) {
    case ReasonConstants.FETCH_ALL_REASON:
    case ReasonConstants.ADD_REASON:
    case ReasonConstants.DELETE_REASON:
      console.log("here we are");
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case ReasonConstants.SUCCESS_FETCH_REASON:
      let reasonList = action && action.reasonList ? action.reasonList : [];
      return {
        ...state,
        reasonList: reasonList,
        isLoading: false,
      };
    case ReasonConstants.SUCCESS_ADD_REASON:
    case ReasonConstants.SUCCESS_DELETE_REASON:
      return {
        ...state,
        isLoading: false,
        msg: "success",
      };
    case ReasonConstants.FAILURE_FETCH_REASON:
    case ReasonConstants.FAILURE_ADD_REASON:
    case ReasonConstants.FAILURE_DELETE_REASON:
      let userMsg = action && action.error ? action.error : {};
      return {
        ...state,
        msg: userMsg,
        isLoading: false,
      };

    default:
      return state;
  }
}
