import { db, firebaseApp } from "../../services/firebase/firebase.config";
import { UserConstant } from "../constants/user.constant";
import { Role, UserModel } from "../../redux/models/user";
import { ClientModel } from "../models/client";

export const userActions = {
  fetchUserList,
  addUser,
  clearMsg,
  clearUserList,
  updateUser,
  addClient,
  fetchClient,
};

function addClient(clientUser: ClientModel) {
  return async (dispatch: (arg0: { type: String; client?: any; error?: any }) => void) => {
    dispatch(request());
    const userCollection = db.collection("clients");
    try {
      var userQueryData = await userCollection.get();
      userQueryData.docs.map((data) => {
        var user = data.data();
        if (user.phone_number === clientUser.phone) {
          throw new Error("Phone Already Exists");
        }
        if (user.email === clientUser.email) {
          throw new Error("Email Already Exists");
        }
        return user;
      });
      clientUser.createdAt = new Date();
      clientUser.updatedAt = new Date();
      await userCollection.add(clientUser);
      dispatch(success("User Created Successfully"));
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }
    function success(client: any) {
      return { type: UserConstant.SUCCESS_ADD_NEW_USER, client };
    }
    function failure(error: any) {
      return { type: UserConstant.FAILURE_ADD_NEW_USER, error };
    }
    function request() {
      return { type: UserConstant.SET_ADD_NEW_USER };
    }
  };
}

function fetchClient() {
  return async (dispatch: (arg0: { type: string; usersList?: any; error?: any }) => void) => {
    dispatch(request());
    try {
      var clientData = await db.collection("clients").get();
      let clientList: any[] = [];
      for (var i = 0; i < clientData.docs.length; i++) {
        let data = clientData.docs[i].data();
        data.id = clientData.docs[i].id;
        // console.log("User Data", data);
        clientList.push(data);
      }
      // console.log("User List Value", usersList);
      dispatch(success(clientList));
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }
    function request() {
      return { type: UserConstant.FETCH_CLIENT_LIST };
    }

    function success(clientList: any) {
      return { type: UserConstant.SUCCESS_CLIENT_LIST, clientList };
    }

    function failure(error: any) {
      return { type: UserConstant.FAILURE_CLIENT_LIST, error };
    }
  };
}

function fetchUserList(role: string) {
  return async (dispatch: (arg0: { type: string; usersList?: any; error?: any }) => void) => {
    dispatch(request());
    try {
      db.collection("users")
        .where("role", "==", role)
        .get()
        .then(async (usersData) => {
          let usersList: any[] = [];
          for (var i = 0; i < usersData.docs.length; i++) {
            let data = usersData.docs[i].data();
            data.id = usersData.docs[i].id;
            if (data.supervisor) {
              try {
                var supervisor = await db.collection("users").doc(data.supervisor).get();
                if (supervisor.exists) {
                  var supervisorData = supervisor.data();
                  if (supervisorData) {
                    data.supervisor_name =
                      supervisorData.name.first_name + supervisorData.name.last_name;
                  }
                }
              } catch (error) {
                console.log("error", error);
                // throw new Error(error.message);
              }
            }
            if (data.documents) {
              data.docData = (await db.doc(`documents/${data.documents}`).get()).data();
            }
            usersList.push(data);
          }
          // console.log("User List Value", usersList);
          dispatch(success(usersList));
        });
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }
    function request() {
      return { type: UserConstant.FETCH_USER_LIST };
    }

    function success(usersList: any) {
      return { type: UserConstant.SUCCESS_USER_LIST, usersList };
    }

    function failure(error: any) {
      return { type: UserConstant.FAILURE_USER_LIST, error };
    }
  };
}

function addUser(userData: UserModel, password?: String) {
  return async (dispatch: (arg0: { type: String; user?: any; error?: any }) => void) => {
    dispatch(request());
    // console.log("userData", userData);
    const userCollection = db.collection("users");
    try {
      var userQueryData = await userCollection.get();
      userQueryData.docs.map((data) => {
        var user = data.data();
        if (userData.role === Role.rider || userData.phone_number?.trim() !== "") {
          if (user.phone_number === userData.phone_number) {
            throw new Error("Phone Already Exists");
          }
        }
        if (user.email === userData.email) {
          throw new Error("Email Already Exists");
        }
        return user;
      });
      userData.createdAt = new Date();
      userData.updatedAt = new Date();
      if (userData.role !== Role.rider && password) {
        // const firebaseAuth =
        const newUser = await firebaseApp
          .auth()
          .createUserWithEmailAndPassword(userData.email.toString(), password.toString());
        console.log("new User created:", newUser);
      }
      await userCollection.add(userData);
      dispatch(success("User Created Successfully"));
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }
  };

  function request() {
    return { type: UserConstant.SET_ADD_NEW_USER };
  }

  function success(newUser: any) {
    return { type: UserConstant.SUCCESS_ADD_NEW_USER, newUser };
  }

  function failure(error: any) {
    return { type: UserConstant.FAILURE_ADD_NEW_USER, error };
  }
}

function updateUser(userData: UserModel) {
  return async (dispatch: (arg0: { type: String; user?: any; error?: any }) => void) => {
    dispatch(request());
    console.log("userData", userData);
    const userCollection = db.collection("users");
    try {
      var userQueryData = await userCollection.doc(userData.id?.toString()).get();
      userData.updatedAt = new Date();
      if (userData.docData) {
        var userDoc = userData.docData;
        delete userData.docData;
      }
      console.log("userData", userData);
      if (userQueryData.exists) {
        await userCollection.doc(userData.id?.toString()).set(userData);
        userData.docData = userDoc;
        dispatch(success("User updated Successfully"));
      } else {
        dispatch(failure("User Not Found"));
      }
    } catch (error) {
      if (error instanceof Error) dispatch(failure(error.message));
      else dispatch(failure(error));
    }
  };

  function request() {
    return { type: UserConstant.SET_UPDATE_USER };
  }

  function success(newUser: any) {
    return { type: UserConstant.SUCCESS_ADD_NEW_USER, newUser };
  }

  function failure(error: any) {
    return { type: UserConstant.FAILURE_ADD_NEW_USER, error };
  }
}

function clearMsg() {
  return (dispatch: any) => {
    dispatch({ type: UserConstant.CLEAR_MSG });
  };
}

function clearUserList() {
  return (dispatch: any) => {
    dispatch({ type: UserConstant.CLEAR_USER_LIST });
  };
}
