import { LoginConstant } from "../constants/auth.constant";
import { db, firebaseApp } from "../../services/firebase/firebase.config";

export const authActions = {
  login,
  logout,
  clearMsg,
};

function login(email: any, password: any, rememberMe: any) {
  return async (dispatch: (arg0: { type: string; user?: any; error?: any }) => void) => {
    dispatch(request({ email, password, rememberMe }));
    try {
      const firebaseSignIn = await firebaseApp.auth().signInWithEmailAndPassword(email, password);
      console.log("firebase Login", firebaseSignIn);
      const userCollection = db.collection("users");
      var userQueryData = await userCollection.where("email", "==", email).get();
      const userDetail = userQueryData.docs[0].data();
      localStorage.setItem("user", JSON.stringify(userDetail));
      dispatch(success(userDetail));
    } catch (error) {
      dispatch(failure(error.message ?? error));
    }

    function request(user: { email: any; password: any; rememberMe: any }) {
      return { type: LoginConstant.REQUEST_LOGIN, user };
    }

    function success(user: any) {
      return { type: LoginConstant.SUCCESS_LOGIN, user };
    }

    function failure(error: any) {
      return { type: LoginConstant.FAILURE_LOGIN, error };
    }
  };
}

function logout() {
  return async (dispatch: (arg0: { type: string; user?: any; error?: any }) => void) => {
    dispatch({ type: LoginConstant.LOG_OUT });
    await firebaseApp.auth().signOut();
  };
}

function clearMsg() {
  return (dispatch: (arg0: { type: string; user?: any; error?: any }) => void) => {
    dispatch({ type: LoginConstant.CLEARM_MSG });
  };
}
