export const UserConstant = {
  FETCH_USER_LIST: "FETCH_USER_LIST",
  SUCCESS_USER_LIST: "SUCCESS_USER_LIST",
  FAILURE_USER_LIST: "FAILURE_USER_LIST",
  SET_ADD_NEW_USER: "ADD_NEW_USER",
  SET_UPDATE_USER: "SET_UPDATE_USER",
  SUCCESS_ADD_NEW_USER: "SUCCESS_ADD_NEW_USER",
  FAILURE_ADD_NEW_USER: "FAILURE_ADD_NEW_USER",
  CLEAR_MSG: "CLEARM_MSG",
  CLEAR_USER_LIST: "CLEAR_USER_LIST",
  FETCH_CLIENT_LIST: "FETCH_CLIENT_LIST",
  SUCCESS_CLIENT_LIST: "SUCCESS_CLIENT_LIST",
  FAILURE_CLIENT_LIST: "FAILURE_CLIENT_LIST",
};
