import { AddressModel } from "./client";
import { ItemModel } from "./item";

export interface TaskModel {
  id?: String;
  order_id?: String;
  task_id?: Number;
  task_type: String; //one of (food_delivery, parcel_delivery, e_commerce_delivery)
  pickup_address: AddressModel;
  sender_id?: String;
  sender_name: String;
  sender_phone: String;
  drop_address: AddressModel;
  receiver_id?: String;
  receiver_name: String;
  receiver_phone: String;
  items?: Array<ItemModel>;
  payment_information: {
    amount: Number;
    payment_type: String;
    payment_remark: String;
  };
  rider?: String; //user_id of the rider (can be null before assignment)
  rider_name?: String;
  rider_phone_number?: String;
  order_status: TaskStatus; //One of TaskStatus
  rejection_reason?: String;
  task_remark: String; // if rejected by Rider then ask reason for rejection.
  createdAt?: Date;
  updatedAt?: Date;
  schedule?: Date;
  timeline?: [
    {
      status: TaskStatus;
      time: Date;
    }
  ];
}

export enum TaskStatus {
  accepted = "accepted",
  assigned = "assigned",
  delivered = "delivered",
  delivery_failed = "delivery_failed",
  in_transit = "in_transit",
  pending = "pending",
  picked_up = "picked_up",
  rejected = "rejected",
  scheduled = "scheduled",
  stale = "stale",
}
