import {applyMiddleware, createStore} from 'redux';
import {rootReducer} from '../reducers';
import thunk from 'redux-thunk';
// @ts-ignore
import logger from 'redux-logger';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger)
);

export default store;
