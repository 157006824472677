import { TaskConstants } from "../constants/task.constant";

const initialState = {
  tasksList: [],
  msg: "",
  isLoading: false,
  userPermissions: [],
  reports: [],
  summaryReport: [],
  ndrReport: [],
  curebayReport: {},
  totalTaskCount: 0,
};

export type AuthInitialStateType = {
  tasksList: object;
  msg: string;
  isLoading: boolean;
  userPermissions: any;
  reports: any;
  summaryReport: any;
  ndrReport: any;
  curebayReport: object;
  totalTaskCount: number;
};
export default function reducer(
  state = initialState,
  action: {
    type: any;
    tasksList: any;
    newTask: any;
    error: any;
    reports: any;
    summaryReport: any;
    ndrReport: any;
    curebayReport: any;
    totalTaskCount: any;
  }
): AuthInitialStateType {
  switch (action.type) {
    case TaskConstants.FETCH_ALL_TASK:
    case TaskConstants.SET_TASK_TO_RIDER:
    case TaskConstants.REQUEST_UPDATE_TASK:
    case TaskConstants.REQUEST_DELETE_TASK:
    case TaskConstants.FETCH_REPORT:
    case TaskConstants.FETCH_CUREBAY_REPORT:
      return {
        ...state,
        isLoading: true,
        msg: "",
      };
    case TaskConstants.SUCCESS_FETCH_TASK:
      let taskList = action && action.tasksList ? action.tasksList : [];
      let totalTaskCount = action && action.totalTaskCount;
      return {
        ...state,
        tasksList: taskList,
        isLoading: false,
        totalTaskCount,
      };
    case TaskConstants.FAILURE_FETCH_TASK:
      let userMsg = action && action.error ? action.error : {};
      return {
        ...state,
        msg: userMsg,
        isLoading: false,
      };
    case TaskConstants.SUCCESS_TASK_TO_RIDER:
      return {
        ...state,
        isLoading: false,
        msg: "success",
      };
    case TaskConstants.FAILURE_TASK_TO_RIDER:
    case TaskConstants.FAILURE_UPDATE_TASK:
    case TaskConstants.FAILURE_DELETE_TASK:
    case TaskConstants.FAILURE_REPORT:
    case TaskConstants.FAILURE_CUREBAY_REPORT:
      return {
        ...state,
        msg: "error",
        isLoading: false,
      };
    case TaskConstants.SUCCESS_UPDATE_TASK:
    case TaskConstants.SUCCESS_DELETE_TASK:
      return {
        ...state,
        isLoading: false,
        msg: "success",
      };
    case TaskConstants.SUCCESS_REPORT:
      let reportList = action && action.reports ? action.reports : [];
      return {
        ...state,
        isLoading: false,
        msg: "",
        reports: reportList,
        summaryReport: action.summaryReport || [],
        ndrReport: action.ndrReport || [],
      };
    case TaskConstants.SUCCESS_CUREBAY_REPORT:
      let curebayReport = action && action.curebayReport ? action.curebayReport : {};
      return {
        ...state,
        isLoading: false,
        msg: "",
        curebayReport: curebayReport,
      };
    case TaskConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
        isLoading: false,
      };

    default:
      return state;
  }
}
