import { ConfigConstant } from "../constants/configs.constant";

const initialState = {
  configs: {},
  msg: "",
  isLoading: false,
  error: false,
};

export type InitialStateType = {
  configs: object;
  msg: string;
  isLoading: boolean;
  error: boolean;
};

export default function reducer(
  state = initialState,
  action: {
    type: any;
    configs: any;
    newTask: any;
    error: any;
  }
): InitialStateType {
  switch (action.type) {
    case ConfigConstant.UPDATE_AUTO_ASSIGN:
    case ConfigConstant.FETCH_CONFIG:
      return {
        ...state,
        configs: {},
        isLoading: true,
        error: false,
      };

    case ConfigConstant.SUCCESS_AUTO_ASSIGN_UPDATE:
      return {
        ...state,
        msg: "success",
        isLoading: false,
        error: false,
      };

    case ConfigConstant.FAILURE_AUTO_ASSIGN_UPDATE:
      return {
        ...state,
        msg: action && action.error ? action.error : "",
        isLoading: false,
        error: true,
      };

    case ConfigConstant.SUCCESS_CONFIG:
      return {
        ...state,
        configs: action.configs ?? {},
        msg: "success",
        isLoading: false,
        error: false,
      };

    case ConfigConstant.FAILURE_CONFIG:
      return {
        ...state,
        msg: action && action.error ? action.error : "",
        configs: {},
        isLoading: false,
        error: true,
      };

    case ConfigConstant.CLEAR_MSG:
      return {
        ...state,
        msg: "",
        isLoading: false,
      };

    default:
      return state;
  }
}
